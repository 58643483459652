import { handleDefaultModal } from "./utils";

const defaultId = "default";
export const modalSelector = "#form-modal";
const formSelector = "#demonstration-form-modal";

const modal = document.querySelector(modalSelector);
const header = modal ? modal.querySelector("[data-modal-header]") : null;
const text = modal ? modal.querySelector("[data-modal-text]") : null;
const buttonText = modal ? modal.querySelector("[data-modal-button]") : null;
const modalHeaderInput = modal ? modal.querySelector("input[name=modal-header]") : null;

const demonstrationForm = document.querySelector(formSelector);
const nameField = demonstrationForm
  ? demonstrationForm.querySelector("[name=name]")
  : null;
const emailField = demonstrationForm
  ? demonstrationForm.querySelector("[name=email]")
  : null;
const phoneField = demonstrationForm
  ? demonstrationForm.querySelector("[name=phone]")
  : null;
const messageField = demonstrationForm
  ? demonstrationForm.querySelector("[name=message]")
  : null;

const content = {
  default: {
    header: "Request a demo",
    text: "",
    button: "Send a request",
  },
  get_price: {
    header: "Get a quote",
    text: "Solution price starts from $1 per employee per month. <br>The final price depends on the number of users, duration of use, and additional options. <br><br>To get a quote, please fill out the form below",
    button: "Send a request",
  },
  get_demo: {
    header: "Request a demo",
    text: "We'll be glad to show you all the features of the Talent Rocks platform. <br>Fill out the form below, and we'll contact you to schedule a demo.",
    button: "Send a request",
  },
  get_free: {
    header: "Try for free",
    text: "We'll be glad to introduce you to the platform's features and provide free trial access for your employees. <br>Fill out the form below, and we'll contact you to confirm the details.",
    button: "Send a request",
  },
  get_offer: {
    header: "Request a business proposal",
    text: "Fill out the form below and we'll send you a business proposal by email.",
    button: "Send a request",
  },
};

const onClickShowModal = (e) => {
  resetForm();
  changeContent(
    content[
      e.currentTarget &&
      e.currentTarget.dataset.modalForm &&
      content[e.currentTarget.dataset.modalForm]
        ? e.currentTarget.dataset.modalForm
        : defaultId
    ]
  );

  handleDefaultModal(modalSelector, () => {
    resetForm();
  });
};

const changeContent = (contentItem) => {
  if (header) {
    header.innerHTML = contentItem.header;
  }
  if (text) {
    text.innerHTML = contentItem.text;
  }
  if (buttonText) {
    buttonText.innerHTML = contentItem.button;
  }
  if (modalHeaderInput) {
    modalHeaderInput.value = contentItem.header;
  }
};

const resetForm = () => {
  if (demonstrationForm) {
    demonstrationForm.classList.remove("success");
    demonstrationForm.reset();
  }
  if (nameField) {
    nameField.parentElement.classList.remove("error");
    nameField.disabled = false;
  }
  if (emailField) {
    emailField.parentElement.classList.remove("error");
    emailField.disabled = false;
  }
  if (phoneField) {
    phoneField.parentElement.classList.remove("error");
    phoneField.disabled = false;
  }
  if (messageField) {
    messageField.disabled = false;
  }
};

document
  .querySelectorAll("[data-modal-form]")
  .forEach((btn) => btn.addEventListener("click", onClickShowModal));
