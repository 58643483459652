export const scrollAnimate = () => {
  const startEvent = "load";
  const animateClass = "animate-completed";
  const animateSelector = "[data-animate]";
  const showRatio = 0.85;
  // При изменении 'maxWidth' здесь для корректной работы анимации -- необходимо не забыть исправить также 'min-width' в ./scrollAnimate.scss. 
  const maxWidth = 1024;

  let animateTopOffsets = [];

  const checkElements = () => {
    const currentScrollTop = getScrollTop();
    animateTopOffsets
      .filter((offsetItem) => currentScrollTop > offsetItem.top)
      .forEach((offsetItem) => {
        offsetItem.element.classList.add(animateClass);
      });

    animateTopOffsets = animateTopOffsets.filter(
      (offsetItem) => currentScrollTop <= offsetItem.top
    );
  };

  const onScroll = () => {
    if (animateTopOffsets.length && getScrollTop() > animateTopOffsets[0].top) {
      checkElements();
    }
  };

  const getElementTop = (element) => {
    return (
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top
    );
  };

  const getScrollTop = () => {
    return (
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
    );
  };

  const destroy = () => {
    document.querySelectorAll(animateSelector).forEach((element) => {
      delete element.dataset.animateNoOffset;
      delete element.dataset.animate;
      delete element.dataset.animateDelay;
    });
  };

  return {
    init() {
      if (window.innerWidth < maxWidth) {
        return destroy();
      }

      window.addEventListener(startEvent, () => {
        const animateBlocks = Array.from(
          document.querySelectorAll(animateSelector)
        );

        animateBlocks.forEach((element) => {
          if (getScrollTop() > getElementTop(element) - window.innerHeight) {
            element.classList.add(animateClass);
          } else {
            animateTopOffsets.push({
              top:
                element.dataset.animateNoOffset !== undefined
                  ? getElementTop(element) - window.innerHeight
                  : getElementTop(element) - window.innerHeight * showRatio,
              element,
            });
          }
        });
        animateTopOffsets.sort((a, b) => a.top - b.top);

        window.addEventListener("scroll", onScroll);
      });
    },
  };
};
